.map-details-marker{
  &.map-details-marker-vertical{
    position: absolute !important;
    top: 4em;
    left: 23%;
    height: 180px;
  }

  &.map-details-marker-horizontal{
    position: absolute !important;
    bottom: 2em;
    height: 200px;
    left: 24%;
  }

  & .map-details-marker-streetview{
    height: 200px;
  }
  & .card-body{
    padding: 1.1em;

    & .map-details-marker-close{
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }
}
