.upload-icon-tool{
  & .img-fluid-contain {
    // background-image: url("https://picsum.photos/200/300");
    background-size: 65%, contain;
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    width: 100%;
    padding-top: 45.45%;
    // margin-bottom: 30px;
    height: 100%;
  }


  & .selectgroup{
    padding: 5px 0 0 5px;
    max-height: 68px;
    overflow-y: auto;
  }

  & .selectgroup-item:not(.btn-pagination-fixed) .selectgroup-input + .selectgroup-button {
    min-width: 52px;
    height: 52px;
    width: 52px;
  }

  & .custom-file{
    & > .custom-file-label{
      &::after{
        display: none;
      }
    }
  }
}
