table{
  &.table-filterable tbody td {
    cursor: pointer;
  }

  & small{
    font-size: .8em;
  }

  &.table-fixed-header {
    border-collapse: collapse;
    width: 100%;

    & thead th {
      background-color: #fff;
      position: sticky;
      top: 0;
      // border-top: 1px solid #dee2e6;
      box-shadow: 0px 1px #dee2e6;
      z-index: 2;
    }
  }
}