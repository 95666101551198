.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: #f5f7fb;
  border-radius: 5px;
}

.Progress {
  background-color: #316cbe;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}
.Upload .Row {overflow: initial!important}