
.card-counters h2 {
    font-weight: bold;
}

.card-counters {
    & .card-header {
        background-color: #dae5f5;
        border-color: #cbdbf2;
        color: #24426c;
    }
    & h2{
        line-height: .5em;
    }
    & p{
        line-height: .8em;
    }
}
.card-title {
    width: 100%;
}

.nav.Tab_header_tabs > .nav-item {
    line-height: 0;
}

.btn-cta-top-right {
    width: 15%;
    position: absolute;
    top: 15px;
    right: -25px;
    z-index: 10;
}