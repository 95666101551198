.card-p-0{
    & .card-body{
        padding: 0;
    }
}

.report-title{
    & h4{
        margin: 0;
    }
}

table.table-border-top-0{
    & tr:first-child > td{
        border-top: 0;
    }
}

.table-firstcol-header{
    & tr>td:first-child{
        font-weight: bold;
    }
}

.card-map{
    height: calc(100vh - 170px) !important;
}

.card-streetview{
    height: 19rem !important;
}

.z-index-1{
    z-index: 1;
    position: relative;
}