.table-map.card-map{
    height: 8rem !important;
    width: 8rem !important;
}

.status-edit{
    line-height: 0;
    position: relative;
    margin-top: -5em;
    text-align: right;
    opacity: .5;
    cursor: pointer;

    &:hover{
        opacity: 1;
    }
}

.td-250{
    width: 250px;
}

.td-128{
    width: 128px;
}
.td-135{
    max-width: 135px;
}
.td-gallery{
    & .text-over{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 0px 2px 3px grey;
        cursor: pointer;
    }
    & img{
        cursor: pointer;
    }
}

img.img-manage-maxheight{
    max-height: 82px;
}


.td-selectable-center {
    & .custom-control {
        padding-left: 0.2rem;
    }
    & .fa-check-circle{
        font-size: 1.25em;
    }
}


.form-imagecheck-lg{
    .imagecheck-image {
        opacity: 0.35;
    }
    
    .imagecheck-input:checked ~ .imagecheck-figure > .imagecheck-image {
        opacity: 1;
    }

    .imagecheck-figure:before {
        width: 1.8rem;
        height: 1.8rem;
    }
}