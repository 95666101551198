$primary-color : #495057;
$active-color: #467fcf;

.selectgroup-item{
    &.btn-pagination-fixed .selectgroup-input{
        & + .selectgroup-button{
            border-color: transparent;
            z-index: 1;
            color: $active-color;
            background: transparent;
        }
    }

    &:not(.btn-pagination-fixed) .selectgroup-input{
        & + .selectgroup-button{
            min-width: 36px;
            height: 36px;
            width: 36px;
            border-width: 3px !important;
            padding: 0.25rem 0rem !important;
        }

        &:not(:checked) + .selectgroup-button {
            border-color: transparent;
            z-index: 1;
            color: $primary-color;
            background: transparent;
        }

        &:checked + .selectgroup-button {
            border-color: $active-color;
            z-index: 1;
            color: $active-color;
            background: transparent;
        }
        
    }
}

