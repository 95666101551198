.card-counters-report{
    padding-bottom: 1rem;
    & h2{
        font-weight: bold;
        line-height: 1rem;
        margin: 0
    }
}

.certification-filter{
    // cursor: pointer;
    transition: opacity .6s ease-in-out;
    &:hover{
        opacity: .8;
    }
    &.certification-filter-disabled{
        opacity: .10;
    }
}

.certification-filter-btn{
    font-size: .8em;
    font-weight: bold;
    text-decoration: none;
    &:hover{
        text-decoration: none;
        opacity: .8;
    }
}