.planner-query-builder-container{
  padding: 0 !important;
  & *{
    border-radius: 3px;
  }

  & .planner-query-builder{
    margin: 0;

    & .ant-btn{
      text-shadow: none;
      box-shadow: none;

      &.ant-btn-{
        &primary {
          color: #fff;
          background-color: #467fcf;
          border-color: #467fcf;
        }
        &danger{
          color: #fff;
          background-color: #cd201f;
          border-color: #cd201f;
        }
      }
    }
    & .group-or-rule-container{
      padding: 0;
    }

    & .group {
      background: #fff;
      // border: 1px solid rgba(210, 210, 210, 0.5);
      border: 0;
    }
  }
}
