.GoogleMapContainer {
  height: 100%;
}

.GoogleMapContainer.blackAndWhite {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}


.gmaps-custom {
  height: 100%;

  & .gmaps-custom-btn{
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
  }

  & .gmaps-custom-component-minimal{
    height: 100%;
  }
}

.gmaps-custom-fullscreen .gmaps-custom-btn{
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 80;
}

.gmaps-custom-fullscreen {
  & .gmaps-custom-component{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 70;
  }

  & .gmaps-custom-loading{
    background-color: #ffffff;
  }
}

.cluster-markers div{
  font-weight: bolder !important;
  text-shadow: 0px 0px 2px #ffffff;
  font-size: 1.2em !important;
}

.cluster-markers img{
  width: inherit !important;
}

.panelsmap-button-group{
  position: absolute;
  &.panelsmap-button-group-top{
    top: 0;
  }
  &.panelsmap-button-group-bottom{
    bottom: 0;
    &.opacity-8{
      opacity: .8;
      -webkit-transition: opacity .5s ease-in-out;
      -moz-transition: opacity .5s ease-in-out;
      -ms-transition: opacity .5s ease-in-out;
      -o-transition: opacity .5s ease-in-out;
      transition: opacity .5s ease-in-out;
      &:hover{
        opacity: 1;
      }
    }
  }
}

.legend-icon{
  width: .9em;
  margin-right: .5em;
}
.legend-text{
  font-size: .9em;
  color: black;
}

.panelsmap-numbers{
  width: 120px !important;
  height: 120px;
  border-radius: 50% !important;
  text-align: center;
  padding: 1em;
  margin-left: 11px;
  border: 2px solid rgba(0, 40, 100, 0.12) !important;
}

.panelsmap-card{
  width: 200px !important;
  height: 170px;
  border-radius: 2px !important;
  text-align: center;
  padding: 1em;
  margin-left: 11px;
  left: -12px;
  position: absolute !important;
  // border: 2px solid rgba(0, 40, 100, 0.12) !important;
}


.map-poi-input{
  &::placeholder{
    color: #bbb !important;
  }
}