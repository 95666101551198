.tabs-user-manage{
  & .margin-bottom-24{
    border-bottom: 1px solid rgba(0, 40, 100, 0.12) !important;
    margin-bottom: 10px !important;
  }

  & .form-fieldset{
    padding: .4em .7em;
  }

  & .Tab_header_tabs{
    display: none;
  }

  & .btn-tab-active{
    border-bottom: 2px solid rgb(70, 127, 207) !important;
    border-radius: 0;
    &:hover{
      border-bottom: 2px solid rgb(70, 127, 207);
    }
  }

}

.pac-container.pac-logo{
  z-index:99999999 !important;
}

.dropdown-block {
  width: 100%;
  & > button{
    display: block;
    width: 100%;
  }
}


.dimmer-opacity {
  &.active {
    & > .dimmer-content {
      opacity: 0.2 !important;
    }
  }
}

.opacity-2{
  opacity: 0.2 !important;
}


.width-50{
  width: 50px;
}
