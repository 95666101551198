.page-main .header{
    position: relative;
    z-index: 10;
}

.IconDrop .dropdown-menu {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    min-width: 20rem;
    padding: 0;

    & a{
        &.dropdown-item-disabled{
            *{
                opacity:.5;
                cursor: no-drop;
            }
        }
        & *{
            transition: all .5s ease-out;
        }
    }
}

.IconDrop .dropdown-toggle::after{
    display: none;
}
.IconDrop .dropdown-item {
    display: inline-block;
    width: 33%;
    padding: 1em;
    font-weight: 400;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border: 0;
    /* float: left; */
}
a.icon_report ~ .SubDropdown{
    display: none;
    opacity: 0;
}

a.icon_report:hover ~ div.SubDropdown,
div.SubDropdown:hover{
    background-color:#467fcf;
    text-align: center;
    color:#fff;
    display:block;
    margin-top:0px;
    z-index:2;

    opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration: .3s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

a.dropdown-item.icon_report:hover{
    // border-bottom: solid 2px #467fcf;
}

a.dropdown-item.icon_report p{
    color: #467fcf;
}

.SubDropdown a, .SubDropdown a p {
    color:#fff;
}

.SubDropdown .dropdown-item:hover,
.SubDropdown .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color:rgba(0, 32, 101, 0.40);
}

.IconDrop .dropdown-item a {
    text-decoration: none !important;
    color: #467fcf;
}

ul.navigation.navbar-nav.mr-auto {
    display: -webkit-box;
}

.icond.nav-tabs {
    border-bottom: 1px solid transparent!important;
    & .nav-link {
        color: inherit;
        border-bottom: 2px solid transparent !important;
    }
}

.icond a.nav-link{
    padding-left: 1.2em!important;
    padding-right: 1.2em!important;
    padding-top: 0.2em;
    // font-weight: bold;
    text-transform: uppercase;
}
.icond .nav-item{
    padding:0px!important;
    position: inherit!important;
}

.icond .dropdown-menu {
    width: auto;
    text-align: center;
    margin-top: 0px!important;
    margin-left: 5px;
    transform: translate3d(0, -100px, 0px);
    // transition: transform 0.5s;
    // z-index: -1;
    max-height: 82px;
    padding: 0;

    & .container {
        max-width: 55%;
    }
}
.icond .dropdown-item {
    // display: inline;
    display: table-cell;
    vertical-align: middle;
    width: 1%;
}
.icond .dropdown-menu-arrow:after {
    position: absolute;
    top: 0px!important;
    left: 0px!important;
    display: inline-block;
    border-right: 0px solid transparent;
    border-bottom: 0px solid #fff;
    border-left: 0px solid transparent;
    content: "";
}

.header .dropdown-menu{
width: 100%;
}

.red{
    height: 72px;
    background-color: red!important;
}

.children:hover .dropdown-menu {
    display:block;
}


a.nav-link.children {
    background-color: transparent !important;
    & > i.fe{
        transition: transform 0.5s;
    }
}

.nav-item.show > a.nav-link.children > i.fe{
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.nav-link.nav-link-active {
    border-color: #a5adbdf7 !important;
    color: #6e7687;
}

.nav-tabs .nav-item {
    margin-right: 2px;
}


.icond .dropdown-menu-arrow:before{
    display: none !important;
}

.show > .nav-link.nav-link-primary.children {
    border-color: #457fcf !important;
    color: #457fcf !important;
}

.header-brand{
  margin-right: 0 !important;
  &-img{
    margin-right: 0 !important;
  }
}
