.card-counters {
  &-chart{
    max-height: 135px;
  }

  & .card-header {
    background-color: #fafafa;
    border-color: #dee2e6;
    color: #495057;
  }

  & .card-body{
    max-height: 240px;
  }
}