.dividers{
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: solid 1px #efefef
}
.avatar-xxl {
  width: 200px !important;
  height: 200px !important;
  line-height: 5rem;
  font-size: 2rem;
}
.avatar{
  margin: 0 auto;
}