.btn-big{
    color: grey;
    font-size: 1.5em !important;
    & i{
        font-size: 5em !important;
        display: block;
        padding: .2em;
    }
}

.dropdown-menu-overflow-y{
    & > .dropdown-menu{
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 350px;
        min-width: 20rem;
    }
}

.modal-lg{
  @media (min-width: 1024px) {
    max-width: 1024px !important;
  }
}

.page-content-wide{
  & > .container{
      width: 100%;

      // Extra large devices (large desktops, 1200px and up)
      @media (min-width: 1200px) {
          max-width: 1500px;
      }

      & .page-header{
        margin: 1.5rem 0 1rem;
      }

      & .sidebar-mw-300{
          max-width: 300px;
          max-height: 400px;
      }
      & .sidebar-mh-400{
        // max-height: 400px;
        min-height: 200px;
      }
      & .main-content-auto{
          display: grid;
      }
  }
  &.page-content-wide-fluid {
      & > .container{
          width: 100%;
          max-width: none;
      }
  }

  // da fixare
  // &.page-content-wide-guest{
  //   & > .page-header{
  //     margin: 0 !important;
  //     padding: 1rem 0 1rem !important;
  //   }
  // } 
}

.text-table-sm{
    max-width: 18em;
    margin: .4em 0;
}

.line-height-2 {
    line-height: 2em;
}

.dropdown-item.active,
.dropdown-item:active{
    background-color:#fff !important;
}

.card-planner-plan-results{
    // min-height: calc(100vh - 15em);
    height: 100%;
    & .card-header{
      & .card-options{
        width: 41%;
        display: block;
      }
    }
    & .card-body{
      &.card-body-map{
          height: calc(100vh - 22em);
      }
      & .card-map{
          height: 100% !important;
          min-height: calc(100vh - 22em);
          & .gmaps-custom{
              min-height: inherit;
          }
      }
    }

    & .planner-results-filter{
        & .dropdown-menu{
            min-width: 18rem;
            & > a.dropdown-item{
              margin-left: 0;
              margin-right: 1em;
            }
        }
    }
}

.card-planner-filters{
    padding: .75em .95em !important;
}

.planner-page{
    & .sidebar-mw-300{
        & .card-title{
            font-size: 1.1em;
        }
    }
}

.max-height-{
  &500, &600{
    overflow-y: auto;
  }
  &500{
    max-height: 500px;
  }
  &600{
    max-height: 600px;
  }
}

.planner-nav-card{
  & .card-header{
    padding: 0 1.5rem;

    & .nav-link{
      line-height: .5em;
      font-size: .9em;


      // sì, fa schifo, poi lo sistemo..
      & .badge{
        position: relative;
        margin-left: 0.5em;
        margin-bottom: -5px;
        margin-top: -3px;
        padding: 0.5em 0.4em;
        line-height: 0.4em;
      }
    }
  }

}

.table-transition-height{
  & tr.tr-transition{
    -webkit-animation: fadeInFromNone 0.5s ease-out;
    -moz-animation: fadeInFromNone 0.5s ease-out;
    -o-animation: fadeInFromNone 0.5s ease-out;
    animation: fadeInFromNone 0.5s ease-out;
  }
}

.tabs-dooh-ambiences{
  & .ant-tabs-nav-list{
    width: 200px;
  }
}

@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.card-minimap{
  height: 160px !important;
  width: 160px;
  &-fullwidth{
    width: 100%;
    height: 100% !important;
  }
}


.font-weight-600{
  font-weight: 600 !important;
}


.text-ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/* Overrides for AntDesign */
.ant-switch-checked,
.ant-steps-navigation .ant-steps-item::before,
.ant-radio-inner::after {
  background: #467fcf;
}

.ant-pagination-item-active,
.ant-checkbox-checked .ant-checkbox-inner{
  border-color: #467fcf;
}

.ant-steps-item-process .ant-steps-item-icon,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-btn-primary {
  background: #467fcf;
  border-color: #467fcf;
}

.ant-steps-item-finish .ant-steps-item-icon{
  border-color: #467fcf;
  & > .ant-steps-icon{
    color: #467fcf;
  }
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
}


.summary-table-cell-fix-left{
  position: sticky;
  left: 0;
}


.card-body-planner-dooh{
  & .card-map{
    height: calc(100vh - 20rem) !important;
  }
}

.card-body-planner-dooh-guest{
  & .card-map{
    height: calc(100vh - 84px) !important;
  }
}

.btn-list-flex {
  & > .btn-list{
    display: flex;
  }
}



.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #467fcf !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #467fcf !important;
  border-color: #467fcf !important;
}

#search-plan input.form-control{
  padding-top: 0.440rem;
  padding-bottom: 0.440rem;
}